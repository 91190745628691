import React, { useState } from "react"
import Layout from "hoc/Layout"
import Font18 from "components/Fonts/Font18"
import Font16 from "components/Fonts/Font16"
import Link from "components/Buttons/Link"
import { graphql } from "gatsby"
import { Alert, Grid, useMediaQuery } from "@mui/material"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { companyData } from "../constants/companyData"
import { options } from "constants/richTextOptions"
import JobOfferCard from "../containers/JobOffersForNannys/JobOfferCard/JobOfferCard"
import { theme } from "../theme/theme"
import ApplicationFormModal from "components/AppliccationFormModal/ApplicationFormModal"
import { ApplyButton } from "../containers/JobOffersForNannys/JobOfferCard/styles"

const Content = ({ pageData }) => {
  const isMd = useMediaQuery(theme.breakpoints.down("md"))
  const is700 = useMediaQuery(theme.breakpoints.down("700"))
  const is500 = useMediaQuery(theme.breakpoints.down("500"))
  const [openModal, setOpenModal] = useState(false)

  const { city, jobDescription, startDate, title } = pageData

  const getPaddingForText = () => {
    if (is500) {
      return 1
    }
    if (is700) {
      return 3
    }
    if (isMd) {
      return 5
    }
    return 10
  }

  const handleClick = () => {
    setOpenModal(true)
  }

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <JobOfferCard {...pageData} offerDetails />
      </Grid>
      <Grid container mt={3} mb={5} px={getPaddingForText()}>
        {renderRichText(jobDescription, options)}
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent={is500 && "center"}
        direction="row"
        gap={"10px"}
        px={getPaddingForText()}
        flexWrap={is500 ? "wrap" : "noWrap"}
      >
        <Alert
          severity="info"
          style={{
            marginBottom: "20px",
            border: "none",

            ".MuiPaper-root": {
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <Font16 textAlign="center" mb={0}>
            Zainteresowała Cię nasz oferta? Zadzwoń do nas pod numer:{" "}
          </Font16>
          <a
            style={{
              color: "#544485",
            }}
            href={`tel:${companyData.telephone}`}
          >
            {companyData.telephoneFormatted}
          </a>
        </Alert>
        <ApplyButton style={{ marginBottom: "20px" }} onClick={handleClick}>
          Aplikuj
        </ApplyButton>
      </Grid>
      <Grid
        container
        px={getPaddingForText()}
        justifyContent={is500 && "center"}
      >
        <Link
          underline
          to="/dla-opiekunki/oferty-pracy-dla-opiekunki-osob-starszych"
        >
          <Font18>Zobacz wszystkie oferty</Font18>
        </Link>
      </Grid>

      {openModal && (
        <ApplicationFormModal
          offerId={`Wysłano z oferty: ${title} | ${city} | ${startDate}`}
          openModal={openModal}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  )
}

const JobOfferForNannyDetails = ({ data }) => {
  const pageData = data.contentfulOfertaPracyDlaOpiekunki2

  const { city, startDate } = pageData

  const breadcrumbsData = [
    {
      title: "Strona Główna",
      link: "/",
    },
    {
      title: "Dla Opiekunki",
      link: "/dla-opiekunki/etapy-rekrutacji-opiekunki-osob-starszych",
    },
    {
      title: "Oferty pracy dla Opiekunki Osób Starszych",
      link: "/dla-opiekunki/oferty-pracy-dla-opiekunki-osob-starszych",
    },
    `Oferta | ${city} - ${startDate}`,
  ]

  return (
    <Layout breadcrumbsData={breadcrumbsData} seoTitle={`Oferta  ${city}`}>
      <Content pageData={pageData} />
    </Layout>
  )
}

export const query = graphql`
  query JobOfferPage($contentful_id: String) {
    contentfulOfertaPracyDlaOpiekunki2(contentful_id: { eq: $contentful_id }) {
      id
      additionalInformation
      additionalInformationUndefined
      city
      contentful_id
      country
      createdAt(formatString: "DD.MM.yyyy")
      currency
      land
      language
      orderType
      salary
      sex
      shortOrderDescription {
        shortOrderDescription
      }
      startDate(formatString: "DD.MM")
      title
      jobDescription {
        raw
      }
    }
  }
`

export default JobOfferForNannyDetails
